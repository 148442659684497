<template>
  <v-app data-cy="app">
    <HeaderLanding/>
    <router-view/>
    <FooterLanding />
  </v-app>
</template>

<script>

import axios from 'axios'
import { mapState } from 'vuex';

import HeaderLanding from './views/HeaderLanding.vue';
import FooterLanding from './views/FooterLanding.vue';
import AdvertismentBlock from './components/adverts/AdvertismentBlock.vue';
import AgregatorStore from "./store";

import "./scss/Landing.scss";

export default {
  name: 'AppLanding',
  store: AgregatorStore,
  beforeCreate() {
    this.$store.dispatch("getCitiesList");
    this.$store.dispatch("setInitData");
  },
  computed: {
    ... mapState({
    })
  },
  methods: {
  },
  components: {
    HeaderLanding,
    FooterLanding,
  },
}
</script>
