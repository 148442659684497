<template>
  <div style="z-index: 1;" >
    <v-toolbar
      color="blue"
      flat
      dark
      class="header-toolbar"
      itemscope itemtype="http://schema.org/Organization"
    >
      <v-icon v-if="isMobile" @click="drawer = true" large style="margin: 0 5px 5px 0;" >{{ IconMenu }}</v-icon>
      <v-toolbar-items style="min-width: 130px; padding-top: 3px;">
        <router-link :to="{ name: 'Home'}" >
          <v-img itemprop="logo" src="../assets/poiskokna_images/logo2.png" height="60" width="200" contain></v-img>
        </router-link>
      </v-toolbar-items>

      <v-toolbar-items v-if="!isMobile" class="d-flex justify-end align-center ">
        <HeaderMenu />
      </v-toolbar-items>

      <meta itemprop="name" content="Poiskokna">
      <meta itemprop="telephone" content="+7 (800) 500-40-33">
      <div itemscope itemprop="address" itemtype="http://schema.org/PostalAddress">
        <meta itemprop="addressLocality" content="Москва">
      </div>

      <v-toolbar-items class="hidden-sm-and-down header-menu">
      </v-toolbar-items>


      <v-spacer v-if="!isMobile"></v-spacer>
      <v-toolbar-items class="d-flex justify-end align-center ">
        <div @click="selectCity" align-center class="mx-lg-2 selected-list-inline">
          <v-icon v-if="!isMobile" x-small>fa fa-map-marker</v-icon>
          <span v-if="selected_cities.length">{{ selected_cities[0].name }}</span>
          <span v-else>Россия</span>
          <v-badge
            style="top: -6px; left: -8px;"
            color="orange"
            :content="selected_cities.length"
            v-if="selected_cities.length"
          >
          </v-badge>
        </div>
      </v-toolbar-items>

    </v-toolbar>

    <v-navigation-drawer
      v-if="isMobile"
      v-model="drawer"
      absolute
    >
      <v-list
        nav
      >
        <v-list-group
          v-for="group in header_menu"
        >
          <template v-slot:activator>
            <v-list-item-title>{{ group.title }}</v-list-item-title>
          </template>

          <v-list-item
            v-for="item in group.items"
            link
          >
            <router-link itemprop="url" :to="{ name: 'WithStatic', params: { sp_slug: item.slug }}" >
              {{ item.name }}
            </router-link>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <CitySelectDialog />
  </div>
</template>

<script>

import "../scss/components/HeaderToolbar.scss"

import { mapState } from "vuex";
import { mdiPhone, mdiMenu } from '@mdi/js';
import { defineAsyncComponent } from 'vue'

export default {
  components: {
		ShopingCartIconMobile: defineAsyncComponent(() => import("../components/ShopingCartIconMobile.vue")),
		HeaderMenu: defineAsyncComponent(() => import("../components/landing/HeaderMenu.vue")),
		CitySelectDialog: defineAsyncComponent(() => import("../components/CitySelectDialog.vue")),
  },
  computed: mapState({
    isMobile: (state) => state.isMobile,
    origin: (state) => state.initialDataM.origin,
    selected_cities: (state) => state.citiesM.selected_cities,
		city_select_dialog: (state) =>  state.citiesM.city_select_dialog,
    header_menu: (state) => state.LandingM.common_params.header_menu,
  }),
  data() {
    return {
      IconMenu: mdiMenu,
      drawer: false,
      group_active: 0,
      IconPhone: mdiPhone,
    }
  },
	methods: {
		selectCity() {
			this.$store.dispatch("select_one_city");
		}
	}
}
</script>

<style type="text/css" scoped media="screen">
.v-list--nav .v-list-group .v-list-item:not(:last-child):not(:only-child), .v-list--rounded .v-list-item:not(:last-child):not(:only-child) {
  margin: 0;
}
.v-list-group .v-list-item__title {
  font-size: 0.8rem;
  color: black;
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  text-indent: 0.0892857143em;
  text-transform: uppercase;
}
.v-list-group .v-list-item {
  min-height: 30px;
}
.v-list-group .v-list-item a {
  font-size: 0.8rem;
  text-decoration: none;
}
</style>
