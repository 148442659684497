/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import { createApp } from "vue";

import App from '../AppLanding.vue';
import vuetify from "../plugins/vuetify";
import router from '../router_landing';
import store from '../store';
import FontAwesomeIcon  from '../plugins/fontawesome';

import '../plugins/axios';

import VueMyToasts from 'vue-my-toasts'
import BootstrapComponent from "../components/BootstrapComponent.vue";

import VueCookies from 'vue-cookies';

const vue = createApp(App);

document.addEventListener('DOMContentLoaded', () => {
  vue
    .component("FontAwesomeIcon", FontAwesomeIcon)
    .use(vuetify)
    .use(VueMyToasts, {
      component: BootstrapComponent,
      options: {
        position: 'top-left',
      },
    })
    .use(router)
    .use(store)
    .use(VueCookies)
    .mount('#app');
})
