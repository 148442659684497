<template>
  <v-footer
    padless
    color="white"
    class="landing__footer"
  >
      <v-row v-if="header_menu && header_menu.length > 0" class="footer_row my-3 mx-4 mx-md-auto justify-space-between text-caption font-weight-bold grey--text text--lighten-1" no-gutters>
        <v-col v-for="sp in headerMenuStaticPages" cols="12" sm="6" md="3" lg="3" xl="3"  style="white-space: nowrap; overflow: hidden;">
          <router-link :to="{ name: 'WithStatic', params: { sp_slug: sp.slug }}" >
            {{ sp.name }}
          </router-link>
        </v-col>
      </v-row>
      <v-row class="footer_row my-3 mx-4 mx-md-auto justify-space-between text-caption font-weight-bold grey--text text--lighten-1" no-gutters>
        <v-col cols="6" sm="6" md="3" lg="3" xl="3" style="white-space: nowrap"><font-awesome-icon :icon='["far","copyright"]' /><span> {{ new Date().getFullYear() }} Все права защищены </span>
        </v-col>
        <v-col cols="6" sm="6" md="3" lg="3" xl="3" ><v-img class="ml-auto ml-md-0" src="../assets/poiskokna_images/logo.png" height="18" width="75" contain></v-img>
        </v-col>
      </v-row>
  </v-footer>
</template>
<script>

import { mapState } from "vuex";

export default {
  name: "FooterLanding",
	computed: {
		...mapState({
      header_menu: (state) => state.LandingM.common_params.header_menu,
		}),
    headerMenuStaticPages() {
      return this.header_menu.map(group => group.items).flat()
    }
  }
}
</script>

<style scoped type="text/css">
.footer_row {
  max-width: 1080px;
}
.footer_row a {
  color: grey;
  text-decoration: none;
}
</style>
